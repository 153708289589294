import { useCallback, useEffect, useState } from 'react';

const useIsTabActive = () => {
  const [isTabVisible, setIsTabVisible] = useState(true);
  localStorage.setItem("activeTab", 'true')
  const handleVisibilityChange = useCallback(() => {
    console.log('visible changed:', document.visibilityState);
    localStorage.setItem("activeTab", (document.visibilityState === 'visible').toString())
    setIsTabVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isTabVisible;
};

export default useIsTabActive;