import { AbstractConnector } from "@web3-react/abstract-connector";
import { injected, walletconnect, walletlink } from '../connectors';

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
}

export const MATIC_MUMBAI_RPCS = ['https://arbitrum-sepolia-rpc.publicnode.com'];
export const MATIC_MUMBAI_BLOCK_EXPLORERS = ['https://sepolia.arbiscan.io'];
export const MATIC_RPCS = ['https://rpc-mainnet.matic.quiknode.pro'];
export const MATIC_BLOCK_EXPLORERS = ['https://explorer-mainnet.maticvigil.com', 'https://explorer.matic.network', 'https://backup-explorer.matic.network'];

export const MATIC_MUMBAI_RPC_DATA = {
  chainId: '0x66C5E', // convert to hex
  chainName: 'arbitrum',
  nativeCurrency: {
    name: 'Hardhat',
    symbol: 'HA',
    decimals: 18
  },
  rpcUrls: MATIC_MUMBAI_RPCS,
  blockExplorerUrls: MATIC_MUMBAI_BLOCK_EXPLORERS,
}

export const MATIC_RPC_DATA = {
  chainId: '0x89',
  chainName: 'Polygon Network',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18
  },
  rpcUrls: MATIC_RPCS,
  blockExplorerUrls: MATIC_BLOCK_EXPLORERS,
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: injected,
    name: "MetaMask",
    iconName: "metamask.png",
    description: "Easy-to-use browser extension.",
    href: null,
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: "WalletConnect",
    iconName: "walletConnectIcon.svg",
    description: "Connect to Trust Wallet, Rainbow Wallet and more...",
    href: null,
  },
  WALLET_LINK: {
    // @ts-ignore
    connector: walletlink,
    name: "Coinbase Wallet",
    iconName: "coinbaseWalletIcon.svg",
    description: "Use Coinbase Wallet app on mobile device",
    href: null,
  },
};
