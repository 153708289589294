import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Styles from './fucate-view-styles.less';
import { useUserStore } from "@augurproject/comps";

export const FucateView = () => {
  const [txHash, setTxHash] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    loginAccount,
  } = useUserStore();

  const handleGetEth = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);
    setLoading(true);
    try {
      console.log("loginAccount", loginAccount)
      // Simulate API call with to address
      const response = await fetch('https://fucate.climat.today/transfer-eth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ to: loginAccount.account }),
      });
      if (!response.ok) throw new Error('Transaction failed');
      const data = await response.json();
      setTxHash(data.txHash);
    } catch (error) {
      setErrorMessage('Transaction failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetUsdc = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);
    setLoading(true);
    try {
      // Simulate API call with to address
      const response = await fetch('https://fucate.climat.today/transfer-usdc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ to: loginAccount.account }),
      });
      if (!response.ok) throw new Error('Transaction failed');
      const data = await response.json();
      setTxHash(data.txHash);
    } catch (error) {
      setErrorMessage('Transaction failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classNames(Styles.centeredContainer)}>
      <section>
        <button className={classNames(Styles.stylishButton)} onClick={handleGetEth} disabled={loading}>Get ETH (0.1 eth)</button>
        <button className={classNames(Styles.stylishButton)} onClick={handleGetUsdc} disabled={loading}>Get USDC (400 $)</button>
        <br />
        <br />
        {loading && <div>Please wait...</div>}
        {errorMessage && (
          <div style={{ color: 'red' }}>{errorMessage}</div>
        )}
        {txHash && (
          <a className={classNames(Styles.transactionLink)} href={`https://sepolia.arbiscan.io/tx/${txHash}`} target="_blank" rel="noopener noreferrer">
            View Transaction: {txHash}
          </a>
        )}
      </section>
    </div>
  );
};